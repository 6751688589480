<template>
  <div class="all_main_wrap" style="min-height: 100%">
    <HeaderBox>
      <template #title> 发票配置 </template>
      <template #input>
        <!-- <a-button class="all_boder_btn" @click="onAdd(2)" v-if="state == 1"
          >编辑汇率</a-button
        > -->
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <!-- <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="data"
        :pagination="false"
        :locale="{ emptyText: '暂无数据' }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        
        <template slot="code2" slot-scope="item">
          <div v-if="state == 1">{{ item }}%</div>
          <a-input v-if="state == 2" style="width: 100px" />
        </template>
        <template slot="operation">
          <div class="btn_router_link">
            <a>编辑</a>
          </div>
        </template>
      </a-table> -->
      <a-table
        :columns="columns"
        :data-source="data"
        bordered
        :rowKey="(item) => item.taxRateConfigId"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="rate" slot-scope="text, record, index">
          <div>
            <!-- 只能输入正整数 -->
            <a-input
              type="number"
              v-if="record.editable"
              :min="1"
              oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              :max="100"
              style="margin: -5px 0"
              :value="text"
              @change="
                (e) =>
                  handleChange(e.target.value, record.taxRateConfigId, index)
              "
            />
            <template v-else> {{ text }}% </template>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => savedata(record)">确定</a>
              &nbsp; &nbsp;
              <a-popconfirm title="确定取消吗" @confirm="() => cancel(record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a
                :disabled="editingKey !== ''"
                @click="() => edit(record.taxRateConfigId)"
                >编辑</a
              >
            </span>
          </div>
        </template>
      </a-table>
    </div>

    <div class="button" v-if="state == 2">
      <a-button type="primary" class="btn" @click="onAdd(1)">确认</a-button>
      <a-button class="all_boder_btn btn">取消</a-button>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productTypeTxt",
  },
  {
    title: "税率",
    align: "center",
    dataIndex: "rate",
    scopedSlots: { customRender: "rate" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      state: 1, // 1展示，2编辑
      visible: false,
      columns, // 表头
      data: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      editingKey: "",
    };
  },
  // 事件处理器
  methods: {
    handleChange(value, rate, column) {
      let newData = [...this.data];
      let target = newData.find((item) => rate === item.taxRateConfigId);
      // console.log(target, column);
      if (target) {
        target.rate = value;
        this.data = newData;
      }
    },
    edit(rate) {
      let newData = [...this.data];
      let target = newData.find((item) => rate === item.taxRateConfigId);
      // console.log(target);
      this.editingKey = rate;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    // 确定
    savedata(e) {
      if (!e.rate) {
        this.$message.warning("税率输入 不能为空");
        return;
      }
      e.rate = e.rate / 100;
      this.$ajax({
        url: "/hxclass-management/tax-rate/config/",
        method: "put",
        params: [e],
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
        } else {
          this.$message.error("操作失败");
        }
      });

      let newData = [...this.data];
      let target = newData.find(
        (item) => e.taxRateConfigId === item.taxRateConfigId
      );
      if (target) {
        target.editable = false;
        this.data = newData;
      }
      this.editingKey = "";
    },
    // 取消编辑
    cancel(e) {
      this.getdataList();
      let newData = [...this.data];
      let target = newData.find(
        (item) => e.taxRateConfigId === item.taxRateConfigId
      );
      if (target) {
        target.editable = false;
        this.data = newData;
      }
      this.editingKey = "";
    },
    // 编辑
    onAdd(state) {
      this.state = state;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    // tab切换
    callback(e) {},
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    // 获取数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/tax-rate/config/",
        method: "get",
        params: {
          // pageNum: this.pageNumber,
          // pageSize: this.pageSize,
          // keyword: this.name,
          // courseId: this.courseid,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          let arr = res.data;
          for (let index = 0; index < arr.length; index++) {
            arr[index].rate = Math.trunc(arr[index].rate * 100);
          }
          this.data = arr;
          this.total = res.data.total;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.button {
  margin-top: 40px;
}
</style>
